import { Modal, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getOrderById, getOrders } from '../api/orderApi';
import { IItem } from '../interfaces/response';
import OrderDetail from './OrderDetail';

const OrderList: React.FC = () => {
    const [orders, setOrders] = useState<IItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [selectedOrder, setSelectedOrder] = useState<IItem | null>(null);
    const [orderDetailVisible, setOrderDetailVisible] = useState<boolean>(false);
    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount);
    };

    const fetchOrders = async (page: number, size: number) => {
        try {
            const { body, status } = await getOrders(page - 1, size);
            if (status === 200) {
                setOrders(body.data);
                setTotalOrders(body.total);
            } else {
                message.error('Failed to fetch orders');
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
            message.error('Error fetching orders');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchOrders(currentPage, pageSize);

        const intervalId = setInterval(() => {
            fetchOrders(currentPage, pageSize);
        }, 6000); // Gọi API mỗi phút

        return () => clearInterval(intervalId); // Dọn dẹp khi component bị unmount
    }, [currentPage, pageSize]);

    const columns = [
        { title: 'Order ID', dataIndex: 'orderCode', key: 'orderCode' },
        { title: 'Khách hàng', dataIndex: 'customer', key: 'customer' },
        { title: 'SĐT', dataIndex: 'phone', key: 'phone' },
        { title: 'Trạng thái', dataIndex: 'status', key: 'status' },
        { title: 'Tổng tiền', dataIndex: 'total_amount', key: 'total_amount', render: (amount: string) => formatCurrency(Number(amount)) },
        { title: 'Tiền ship', dataIndex: 'money_ship', key: 'money_ship', render: (amount: string) => formatCurrency(Number(amount)) },
        { title: 'KM', dataIndex: 'distance', key: 'distance' },
        { title: 'Địa chỉ lấy hàng', dataIndex: 'address_origin', key: 'address_origin', render: (address: string) => <div className="ellipsis">{address}</div> },
        { title: 'Địa chỉ giao', dataIndex: 'address_destination', key: 'address_destination', render: (address: string) => <div className="ellipsis">{address}</div> },

    ];

    const handleRowClick = async (record: IItem) => {
        try {
            const { body, status } = await getOrderById(record.id);
            if (status === 200) {
                setSelectedOrder(body);
                setOrderDetailVisible(true);
            } else {
                message.error('Failed to fetch order details');
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
            message.error('Error fetching order details');
        }
    };

    const handleTableChange = (pagination: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const handleOrderDetailClose = () => {
        setOrderDetailVisible(false);
        setSelectedOrder(null);
    };

    const handleOrderUpdate = () => {
        fetchOrders(currentPage, pageSize);
    };

    return (
        <div>
            <h1>Order List</h1>
            <Table
                loading={loading}
                dataSource={orders}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalOrders,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50'],
                }}
                onChange={handleTableChange}
                scroll={{ x: 'max-content' }}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                style={{ marginTop: '20px' }}
            />
            {selectedOrder && (
                <Modal
                    visible={orderDetailVisible}
                    onCancel={handleOrderDetailClose}
                    footer={null}
                    width={800}
                >
                    <OrderDetail order={selectedOrder} onClose={handleOrderDetailClose} onUpdate={handleOrderUpdate} />
                </Modal>
            )}
        </div>
    );
};

export default OrderList;
