import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import History from './components/Histories';
import Info from './components/Info';
import LoginForm from './components/Login';
import OrderList from './components/Orders';
import TopNavigation from './components/TopNavigation';
import { isAuthenticated as checkAuth } from './utils';
import Delivering from './components/Delivering';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuth());

  return (
    <Layout>
      {isAuthenticated && <TopNavigation setIsAuthenticated={setIsAuthenticated} />}
      <Content style={{ padding: '0 50px', marginTop: 64 }}>
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/orders" /> : <LoginForm setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route path="/orders" element={isAuthenticated ? <OrderList /> : <Navigate to="/" />} />
          <Route path="/delivering" element={isAuthenticated ? <Delivering /> : <Navigate to="/" />} />
          <Route path="/history" element={isAuthenticated ? <History /> : <Navigate to="/" />} />
          <Route path="/info" element={isAuthenticated ? <Info /> : <Navigate to="/" />} />
        </Routes>
      </Content>
    </Layout>
  );
};

export default App;
