import { Button, Descriptions, List, message, Modal, Space } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { deliveringOrder } from '../api/orderApi';
import { IItem } from '../interfaces/response';
import { formatCurrency } from '../utils';

interface OrderDetailProps {
    order: IItem;
    onClose: () => void;
    onUpdate: () => void;
}

const OrderDetail: React.FC<OrderDetailProps> = ({ order, onClose, onUpdate }) => {
    const navigate = useNavigate();

    const handleAcceptOrder = async () => {

        Modal.confirm({
            title: 'Xác nhận đơn hàng',
            content: 'Bạn sẽ giao đơn hàng này chứ',
            onOk: async () => {
                try {
                    const { status } = await deliveringOrder(order.id);
                    if (status === 201) {
                        message.success('Order accepted successfully');
                        onUpdate();
                        onClose();
                        navigate('/delivering');
                    } else {
                        message.error('Failed to accept order');
                    }
                } catch (error) {
                    console.error('Error accepting order:', error);
                    message.error('Error accepting order');
                }
            },
        });
    };
    return (
        <div>
            <Descriptions title="Order Details" bordered>
                <Descriptions.Item label="Order ID">{order.id}</Descriptions.Item>
                <Descriptions.Item label="Khách hàng">{order.customer}</Descriptions.Item>
                <Descriptions.Item label="SĐT Khách hàng">{order.phone}</Descriptions.Item>
                <Descriptions.Item label="Trạng thái">{order.status}</Descriptions.Item>
                <Descriptions.Item label="Tổng tiền">{formatCurrency(order.total_amount)}</Descriptions.Item>
                <Descriptions.Item label="Tiền ship">{formatCurrency(order.money_ship)}</Descriptions.Item>
                <Descriptions.Item label="Khoảng cách">{order.distance} KM</Descriptions.Item>
                <Descriptions.Item label="Nơi nhận hàng">{order.address_origin}</Descriptions.Item>
                <Descriptions.Item label="Nơi giao hàng">{order.address_destination}</Descriptions.Item>
            </Descriptions>
            <List
                header={<div>Items</div>}
                bordered
                dataSource={order.items}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.name}
                            description={
                                <Space>
                                    {item.images.map((image, index) => (
                                        <img key={index} src={image.link} alt={item.name} style={{ width: 50, marginRight: 5 }} />
                                    ))}
                                    <span>Quantity: {item.quantity}</span>
                                    <span>Price: {item.price}</span>
                                    <span>Total: {item.total}</span>
                                </Space>
                            }
                        />
                    </List.Item>
                )}
            />
            <Space style={{ marginTop: 20 }}>
                <Button type="primary" onClick={handleAcceptOrder}>Nhận đơn</Button>
                <Button onClick={onClose}>Close</Button>
            </Space>
        </div>
    );
};

export default OrderDetail;
