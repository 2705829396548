import { Button, Form, Input, Typography, message } from 'antd';
import React from 'react';
import { loginShipper } from '../api/loginApi';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

interface LoginFormData {
  phone: string;
  password: string;
}



interface LoginFormProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForm: React.FC<LoginFormProps> = ({ setIsAuthenticated }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: LoginFormData) => {
    try {
      const { phone, password } = values;
      const response = await loginShipper(phone, password);
      if (response.status === 200) {
        localStorage.setItem('info', JSON.stringify(response.body));
        message.success('Đăng nhập thành công');

        setIsAuthenticated(true);

        navigate('/orders');
      } else {
        message.error(JSON.stringify(response));
      }

      // Xử lý logic sau khi đăng nhập thành công
    } catch (error) {
      message.error('Login error');
      // Xử lý lỗi đăng nhập
    }
  };
  return (
    <div style={{ maxWidth: 300, margin: 'auto' }}>
      <Title level={2} style={{ textAlign: 'center' }}>
        Login
      </Title>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="phone"
          rules={[{ required: true, message: 'Please enter your phone number' }]}
        >
          <Input placeholder="Phone" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;