import React, { useEffect, useState } from 'react';
import { Table, message, Descriptions } from 'antd';
import { IHistory } from '../interfaces/response';
import { historyByShip } from '../api/historiesApi';
import HistoryDetail from './HistoryDetail';

const History: React.FC = () => {
  const [history, setHistory] = useState<IHistory | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const fetchHistory = async (page: number, size: number) => {
    try {
      const { body, status } = await historyByShip(page - 1, size);
      if (status === 200) {
        setHistory(body);
      } else {
        message.error('Failed to fetch history');
      }
    } catch (error) {
      console.error('Error fetching history:', error);
      message.error('Error fetching history');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    setLoading(true);
    fetchHistory(currentPage, pageSize);
  }, [currentPage, pageSize]);



  const columns = [
    { title: 'Order ID', dataIndex: 'id', key: 'id' },
    { title: 'Khách hàng', dataIndex: 'customer', key: 'customer' },
    { title: 'Trạng tháo', dataIndex: 'status', key: 'status' },
    { title: 'Tổng tiền', dataIndex: 'total_amount', key: 'total_amount', render: (amount: number) => formatCurrency(amount) },
    { title: 'Tiền ship', dataIndex: 'money_ship', key: 'money_ship', render: (amount: number) => formatCurrency(amount) },
    { title: 'Khoảng cách', dataIndex: 'distance', key: 'distance' },
    { title: 'Nơi nhận hàng', dataIndex: 'origin', key: 'origin' },
    { title: 'Nơi giao hàng', dataIndex: 'destination', key: 'destination' },
    { title: 'Ngày tạo', dataIndex: 'created_time', key: 'created_time', render: (time: Date) => new Date(time).toLocaleString() },
  ];

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount);
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedInvoice(null);
  };

  const handleRowClick = (record: any) => {
    setSelectedInvoice(record.invoice);
    setIsModalVisible(true);
  };


  return (
    <div>
      <h1>Delivery History</h1>
      {loading ? (
        <p>Loading...</p>
      ) : history ? (
        <div>
          <Descriptions bordered>
            <Descriptions.Item label="Total Successful Deliveries">{history.ship_success}</Descriptions.Item>
            <Descriptions.Item label="Total Failed Deliveries">{history.ship_failed}</Descriptions.Item>
          </Descriptions>
          <Table
            dataSource={history.data}
            columns={columns}
            rowKey={(record) => record.invoice}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: history.total,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50'],
            }}
            scroll={{ x: 'max-content' }}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            style={{ marginTop: '20px' }}
          />
        </div>
      ) : (
        <p>No history available</p>
      )}
      {selectedInvoice && (
        <HistoryDetail
          invoice={selectedInvoice}
          visible={isModalVisible}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default History;
