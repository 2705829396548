import axios from 'axios';
import { refreshToken } from './loginApi';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
    const user = JSON.parse(localStorage.getItem('info') || '{}');
    if (user) {
        const accessToken = user.accessToken;
        const refreshTokenValue = user.refreshToken;

        const isTokenExpired = (token: string) => {
            const decoded = JSON.parse(atob(token.split('.')[1]));
            return decoded.exp * 1000 < Date.now();
        };

        if (isTokenExpired(accessToken)) {
            try {
                const newAccessToken = await refreshToken(refreshTokenValue);
                localStorage.setItem('info', JSON.stringify(user));
                config.headers['token'] = `${newAccessToken.body.accessToken}`;
            } catch (error) {
                localStorage.removeItem('info');
                window.location.href = '/login';
                return Promise.reject(error);
            }
        } else {
            config.headers['token'] = `${accessToken}`;
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;
