import { Descriptions, List, message, Modal, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { IHistoryDetail } from '../interfaces/response';
import { historyDetailByShip } from '../api/historiesApi';
import moment from 'moment';

interface HistoryDetailProps {
    invoice: string;
    visible: boolean;
    onClose: () => void;
}

const HistoryDetail: React.FC<HistoryDetailProps> = ({ invoice, visible, onClose }) => {
    const [historyDetail, setHistoryDetail] = useState<IHistoryDetail | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount);
    };
    
    useEffect(() => {
        if (visible) {
            const fetchHistoryDetail = async () => {
                try {
                    const { body, status } = await historyDetailByShip(invoice);
                    if (status === 200) {
                        setHistoryDetail(body);
                    } else {
                        message.error('Failed to fetch history detail');
                    }
                } catch (error) {
                    console.error('Error fetching history detail:', error);
                    message.error('Error fetching history detail');
                } finally {
                    setLoading(false);
                }
            };

            fetchHistoryDetail();
        }
    }, [invoice, visible]);

    return (
        <Modal
            title="History Details"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            {loading ? (
                <div>Loading...</div>
            ) : historyDetail ? (
                <div>
                    <Descriptions column={2}>
                        <Descriptions.Item label="Order ID">{historyDetail.id}</Descriptions.Item>
                        <Descriptions.Item label="Khách hàng">{historyDetail.customer}</Descriptions.Item>
                        <Descriptions.Item label="Trạng thái">{historyDetail.status}</Descriptions.Item>
                        <Descriptions.Item label="Tổng tiền">{formatCurrency(historyDetail.total_amount)}</Descriptions.Item>
                        <Descriptions.Item label="Tiền ship">{formatCurrency(historyDetail.money_ship)}</Descriptions.Item>
                        <Descriptions.Item label="Khoảng cách">{historyDetail.distance} KM</Descriptions.Item>
                        <Descriptions.Item label="Nơi nhận hàng">{historyDetail.origin}</Descriptions.Item>
                        <Descriptions.Item label="Nơi giao hàng">{historyDetail.destination}</Descriptions.Item>
                        <Descriptions.Item label="Tên cửa hàng">{historyDetail.shop.name}</Descriptions.Item>
                        <Descriptions.Item label="Giao tới">{moment(historyDetail.ship_done).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                        <Descriptions.Item label="Thời gian ship">{historyDetail.total_ship_time}</Descriptions.Item>
                    </Descriptions>
                    <List
                        header={<div>Items</div>}
                        bordered
                        dataSource={historyDetail.invoice.items}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.name}
                                    description={
                                        <Space>
                                            {item.images.map((img, index) => (
                                                <img key={index} src={img.link} alt={img.name} style={{ width: 50 }} />
                                            ))}
                                            <span>Quantity: {item.quantity}</span>
                                            <span>Price: {formatCurrency(item.price)}</span>
                                            <span>Total: {formatCurrency(item.total)}</span>
                                        </Space>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </div>
            ) : (
                <div>No data</div>
            )}
        </Modal>
    );
};

export default HistoryDetail;
