import { BASE_URL, HttpStatus } from "../config";
import { IHistory, IHistoryDetail } from "../interfaces/response";
import axiosInstance from "./axiosInstance";


export const historyByShip = async (
    page?: number,
    size?: number
): Promise<{
    body: IHistory,
    status: HttpStatus,
}> => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/shippers/by-ship?page=${page ? page : 0}&size=${size ? size : 10}`);
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};


export const historyDetailByShip = async (invoice: string): Promise<{
    body: IHistoryDetail,
    status: HttpStatus,
}> => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/shippers/history/${invoice}`);
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};