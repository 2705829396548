import { Button, Descriptions, List, Modal, Space, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cancelShipDeliveringByOrder, doneShipDeliveringByOrder, getDeliveringByOrder } from '../api/orderApi';
import { IDeliveringShip } from '../interfaces/response';


const Delivering: React.FC = () => {
  const [order, setOrder] = useState<IDeliveringShip | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchDeliveringOrder = async () => {
    try {
      const { body, status } = await getDeliveringByOrder();
      if (status === 200) {
        setOrder(body);
      } else {
        message.error('Failed to fetch delivering order');
      }
    } catch (error) {
      console.error('Error fetching delivering order:', error);
      message.error('Error fetching delivering order');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeliveringOrder();
  }, []);

  const handleCompleteOrder = async (invoice: string) => {

    Modal.confirm({
      title: 'Xác nhận đã giao',
      content: 'Bạn đã thực sự giao cho khách hàng ?',
      onOk: async () => {
        try {
          const { status } = await doneShipDeliveringByOrder(invoice);
          if (status === 200) {
            message.success('Bạn đã hoàn thành đơn hàng này.');
            navigate('/orders');
          } else {
            message.error('Failed to complete order');
          }
        } catch (error) {
          console.error('Error completing order:', error);
          message.error('Error completing order');
        }
      },
    });


  };

  const handleCancelOrder = async () => {
    if (!order) return;

    Modal.confirm({
      title: 'Confirm Cancel Order',
      content: 'Bạn muốn huỷ giao đơn hàng này?',
      onOk: async () => {
        try {
          const { status } = await cancelShipDeliveringByOrder(order.id);
          if (status === 200) {
            message.success('Order canceled successfully');
            navigate('/orders');
          } else {
            message.error('Failed to cancel order');
          }
        } catch (error) {
          console.error('Error canceling order:', error);
          message.error('Error canceling order');
        }
      },
    });
  };

  return (
    <div>
      <h1>Delivering Order</h1>
      {loading ? (
        <p>Loading...</p>
      ) : order ? (
        <div>
          <Descriptions title="Order Details" bordered>
            <Descriptions.Item label="Order ID">{order.invoice.id}</Descriptions.Item>
            <Descriptions.Item label="Khách hàng">{order.customer}</Descriptions.Item>
            <Descriptions.Item label="SĐT">{order.ship}</Descriptions.Item>
            <Descriptions.Item label="Trạng thái">{order.status}</Descriptions.Item>
            <Descriptions.Item label="Tổng tiền">{order.total_amount}</Descriptions.Item>
            <Descriptions.Item label="Tiền ship">{order.money_ship}</Descriptions.Item>
            <Descriptions.Item label="Khoảng cách">{order.distance} KM</Descriptions.Item>
            <Descriptions.Item label="Nơi nhận hàng">{order.origin}</Descriptions.Item>
            <Descriptions.Item label="Nơi giao hàng">{order.destination}</Descriptions.Item>
          </Descriptions>
          <List
            header={<div>Items</div>}
            bordered
            dataSource={order.invoice.items}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.name}
                  description={
                    <Space>
                      <img src={item.images[0].link} alt={item.name} style={{ width: 50 }} />
                      <span>Quantity: {item.quantity}</span>
                      <span>Price: {item.price}</span>
                      <span>Total: {item.total}</span>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
          <Space style={{ marginTop: 20 }}>
            <Button type="primary" onClick={() => handleCompleteOrder(order.invoice.id)}>Đã giao</Button>
            <Button danger onClick={handleCancelOrder}>Hủy đơn</Button>
          </Space>
        </div>
      ) : (
        <p>No delivering order</p>
      )}
    </div>
  );
};

export default Delivering;
