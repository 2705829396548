import axios from 'axios';
import { BASE_URL, HttpStatus } from '../config';
import { LoginResponse } from '../interfaces/response';

export const loginShipper = async (phone: string, password: string): Promise<{
    body: LoginResponse,
    status: HttpStatus,
}> => {
    try {
        const response = await axios.post(`${BASE_URL}/auth/shipper`, {
            phone,
            password,
        });
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};


export const refreshToken = async (refreshToken: string): Promise<{
    body: LoginResponse,
    status: HttpStatus,
}> => {
    try {
        const response = await axios.post(`${BASE_URL}/auth/refresh-token`, null, {
            headers: {
                'refresh-token': refreshToken,
            },
        });
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};