import { AppstoreOutlined, DeliveredProcedureOutlined, HistoryOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, Modal } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TopNavigationProps {
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const TopNavigation: React.FC<TopNavigationProps> = ({ setIsAuthenticated }) => {
    const navigate = useNavigate();

    const handleLogout = () => {

        Modal.confirm({
            title: 'Xác nhận đăng xuất',
            content: 'Bạn muốn đăng xuất chứ ?',
            onOk: async () => {
                localStorage.removeItem('info');
                setIsAuthenticated(false);
                navigate('/');
            },
        });

    };

    return (
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['orders']}>
            <Menu.Item
                icon={<AppstoreOutlined />}
                key="orders" onClick={() => navigate('/orders')}>
                Orders
            </Menu.Item>
            <Menu.Item
                icon={<DeliveredProcedureOutlined />}
                key="delivering" onClick={() => navigate('/delivering')}>
                Delivering
            </Menu.Item>
            <Menu.Item
                icon={<HistoryOutlined />}
                key="history" onClick={() => navigate('/history')}>
                History
            </Menu.Item>
            <Menu.Item
                icon={<UserOutlined />}
                key="info" onClick={() => navigate('/info')}>
                Info
            </Menu.Item>
            <Menu.Item
                icon={<LogoutOutlined />}
                key="logout" onClick={handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );
};

export default TopNavigation;
