import { BASE_URL, HttpStatus } from "../config";
import { IDeliveringShip, IItem, IOrderRes, IReciveShip } from "../interfaces/response";
import axiosInstance from "./axiosInstance";



export const getOrders = async (
    page: number = 0,
    size: number = 10
): Promise<{
    body: IOrderRes,
    status: HttpStatus,
}> => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/shippers/?page=${page}&size=${size}`);
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};


export const getOrderById = async (
    id: string
): Promise<{
    body: IItem,
    status: HttpStatus,
}> => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/shippers/${id}`);
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};


export const deliveringOrder = async (
    invoice: string
): Promise<{
    body: IReciveShip,
    status: HttpStatus,
}> => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/shippers/receive/`, {
            invoice
        });
        //  const response = await axiosInstance.post(`${BASE_URL}/shippers/receive`, {
        //     invoice
        // });
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};



export const getDeliveringByOrder = async (
): Promise<{
    body: IDeliveringShip,
    status: HttpStatus,
}> => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/shippers/delivering`);
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};



export const doneShipDeliveringByOrder = async (
    invoice: string
): Promise<{
    body: any,
    status: HttpStatus,
}> => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/shippers/done`, { invoice });
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};




export const cancelShipDeliveringByOrder = async (
    invoice: string
): Promise<{
    body: any,
    status: HttpStatus,
}> => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/shippers/cancel/${invoice}`);
        return { body: response.data, status: response.status };
    } catch (error) {
        throw error;
    }
};


